import cn from 'classnames';
import s from './Safety.module.css';
import Arrow from './assets/arrow.svg?react';

interface ISafetyProps {
  className?: string;
  auditor: {
    audits: number;
    Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    LogoHovered: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    href: string;
  };
}

const Auditor: React.FC<ISafetyProps> = ({ auditor: { href, content, audits } }) => (
  <a className={cn(s.card, s.auditor)}>
    <div className={s.auditorLogoContainer}>
      <div className={s.logoContainer}>
        {content}
        {/* <Logo className={s.logo} />
        <LogoHovered className={s.logoHovered} /> */}
      </div>
    </div>
    <hr />
    <div className={s.audits}>
      <p className={s.auditCompleted}>
        <span>{audits}</span>
      </p>
      <Arrow className={s.arrow} />
    </div>
  </a>
);

export default Auditor;
