import cn from 'classnames';
import Container from '../Container/Container';
import s from './Roadmap.module.css';

interface IProtectionProps {
  className?: string;
}

const Roadmap: React.FC<IProtectionProps> = ({ className }) => (
  <Container className={cn(s.root, className)}>
    <h2 className={s.safetyBeforeAll}>Roadmap</h2>
    <ul className={s.list}>
      <li className={cn(s.item, s.bugBounty)} key="bounty">
        <div className={s.textWrapper}>
          <h2>Q3/2024</h2>
          <span>
          - Build the core infrastructure for SolSwapBot, integrating exchanges like Radium on Solana.
          </span>
          <span>
          - Test essential trading functions and security measures thoroughly.
          </span>
          <span>
          - Launch a limited beta to gather user feedback for improvements.
          </span>
          <span>
          - Engage the community and establish a feedback loop with early adopters.
          </span>
        </div>
      </li>
      <li className={cn(s.item, s.protectionPrioritized)} key="protection">
        <div className={s.textWrapper}>
          <h2>Q4/2024</h2>
          <span>
            - $SSBT Token Listing
          </span>
          <span>
            - Improve the user interface for better usability.
          </span>
          <span>
            - Conduct a second round of beta testing to validate new features and gather feedback.
          </span>
          <span>
            - Expand the SolSwapBot community through educational resources and support.
          </span>
          <span>
          - Add new features to the SolSwapBot ecosystem, including advanced analytics, risk management, and portfolio tracking.
          </span>

          </div>
      </li>
      <li className={cn(s.item, s.protectionPrioritized)} key="tradeprotection">
        <div className={s.textWrapper}>
          <h2>Q1/2025</h2>
<span>
- Boost SolSwapBot's scalability to manage higher trading volumes and user activity.
</span>
<span>
- Pursue partnerships with decentralized projects and exchanges on Solana.
</span>
<span>
- Evaluate support for new assets and expansion to other DEX platforms.
</span>
<span>
- Implement community-driven features based on user feedback.
</span>
          </div>
      </li>
      <li className={cn(s.item, s.protectionPrioritized)} key="tradeprotection">
        <div className={s.textWrapper}>
          <h2>Q2/2025</h2>
<span>
  - Prioritize global outreach and user acquisition to establish SolSwapBot as the leading decentralized trading solution on Solana.
</span>
<span>
  - Ensure regulatory compliance with evolving financial regulations.
</span>
<span>
  - Enhance educational resources and support for diverse users.
</span>
<span>
  - Continuously innovate to stay ahead in decentralized finance.
</span>
<span>
  - Forge partnerships to strengthen the Solana ecosystem.
</span>
        </div>
      </li>
    </ul>
  </Container>
);

export default Roadmap;
