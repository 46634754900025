import cn from 'classnames';
import Container from '../Container/Container';
import s from './Benefits.module.css';
import Octahedron from './assets/1.svg?react';
import Shield from './assets/2.svg?react';
import Dots from './assets/3.svg?react';

interface IBenefitsProps {
  className?: string;
}

const content = [
  {
    icon: <Octahedron className={s.icon} />,
    title: 'Secure Key Management',
    text: 'Sol Swap Bot uses strong key management practices to securely store and handle private keys. By applying industry-standard encryption and secure storage solutions, we prioritize safeguarding user assets and reducing the risk of unauthorized access.',
  },
  {
    icon: <Shield className={s.icon} />,
    title: 'Multi-Factor Authentication',
    text: 'To bolster account security, Sol Swap Bot employs multi-factor authentication (MFA). This added layer of protection, which combines passwords with one-time codes, strengthens defenses against unauthorized access and enhances the overall security of our trading platform.',
  },
  {
    icon: <Dots className={s.icon} />,
    title: 'Regular Security Audits and Updates',
    text: 'Security remains a top priority at Sol Swap Bot. We conduct regular audits to spot and fix vulnerabilities, staying alert to emerging threats. Timely software updates and patches are applied to keep our platform secure against evolving challenges.',
  },
];

const Benefits: React.FC<IBenefitsProps> = ({ className }) => (
  <Container className={cn(s.root, className)}>
    <ul className={s.list}>
      {content.map(({ icon, title, text }) => (
        <li className={s.benefitItem} key={text}>
          {icon}
          <div className={s.textWrapper}>
            <h3 className={s.title}>{title}</h3>
            <p className={s.text}>{text}</p>
          </div>
        </li>
      ))}
    </ul>
  </Container>
);

export default Benefits;
