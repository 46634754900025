import cn from 'classnames';
import { useState } from 'react';
import s from './Banner.module.css';
import Close from './assets/close.svg?react';

interface IBannerProps {
  className?: string;
}

const Banner: React.FC<IBannerProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return null;
};

export default Banner;
