import cn from 'classnames';
import Container from '../Container/Container';
import Auditor from './Auditor';
import OtherAuditors from './OtherAuditors';
import s from './Safety.module.css';
import SafetyScore from './SafetyScore';
import CantinaLogo from './assets/cantinaLogo.svg?react';
import CantinaLogoGray from './assets/cantinaLogoGray.svg?react';
import CertikLogo from './assets/certikLogo.svg?react';
import CertikLogoGray from './assets/certikLogoGray.svg?react';
import Code4renaLogo from './assets/code4renaLogo.svg?react';
import Code4renaLogoGray from './assets/code4renaLogoGray.svg?react';
import OpenZeppelinLogo from './assets/openZeppelinLogo.svg?react';
import OpenZeppelinLogoGray from './assets/openZeppelinLogoGray.svg?react';
import PeckShieldLogo from './assets/peckShieldLogo.svg?react';
import PeckShieldLogoGray from './assets/peckShieldLogoGray.svg?react';
import PessimisticLogo from './assets/pessimisticLogo.svg?react';
import PessimisticLogoGray from './assets/pessimisticLogoGray.svg?react';
import QuantstampLogo from './assets/quantstampLogo.svg?react';
import QuantstampLogoGray from './assets/quantstampLogoGray.svg?react';

interface ISafetyProps {
  className?: string;
}

const auditors = [
  {
    content: "Track and monitor portfolio performance, including profits and losses.",
    audits: "Portfolio Tracking",
  },
  {
    content: "Receive alerts and notifications for key market events, executed trades, and account status changes.",
    audits: "Notification System",
  },
  {
    content: "Tools to evaluate liquidity across trading pairs for efficient order execution.",
    audits: "Liquidity Analysis",
  },
  {
    audits: "Communication Feature",
    content: 'Integration with social platforms for sharing strategies, insights, and learning from other traders.',
  },
  {
    audits: "Automated Execution",
    content: "Automated order placement and execution according to predefined trading strategies."
  },
  {
    audits: "Customizable Trading Strategies",
    content: "Implement and customize trading strategies using technical indicators, market conditions, and user preferences."
  },
  {
    audits: "Risk Management",
    content: "Tools for managing risk, such as stop-loss orders, take-profit orders, and position sizing controls."
  },
];

const Safety: React.FC<ISafetyProps> = ({ className }) => (
  <section className={cn(s.root, className)}>
    <Container className={s.container}>
      <h2 className={s.safetyBeforeAll}>Safety before all</h2>
      <p className={s.assetSecurity}>
        Transact with confidence, knowing SSB places nothing before the security of your assets
      </p>
      <div className={s.auditorsAndScore}>
        <SafetyScore className={s.safetyScoreMobile} />
        <div className={s.logos}>
          {auditors.map(a => (
            <Auditor key={`${a.href}-${a.audits}`} auditor={a} />
          ))}
          <OtherAuditors />
        </div>
        <SafetyScore className={s.safetyScoreDesktop} />
      </div>
    </Container>
  </section>
);

export default Safety;
