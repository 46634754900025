import Container from '../Container/Container';
import s from './Wallets.module.css';

import dexscreenLogoSrc from './assets/dexscreen.webp';
import jupiterLogoSrc from './assets/jupiter.webp';
import orcaLogoSrc from './assets/orca.webp'
import raydiumLogoSrc from './assets/raydium.webp'
import solscanLogoSrc from './assets/solscan.webp'

interface Wallet {
  logoSrc: string;
  name: string;
}

const wallets: Wallet[] = [
  {
    logoSrc: dexscreenLogoSrc,
    name: 'DexScreen',
  },
  {
    logoSrc: jupiterLogoSrc,
    name: 'Jupiter'
  },
  {
    logoSrc: orcaLogoSrc,
    name: 'ORCA'
  },
  {
    logoSrc: raydiumLogoSrc,
    name: 'Raydium'
  },
  {
    logoSrc: solscanLogoSrc,
    name: 'SolScan'
  }
  
];

const Wallets: React.FC = () => (
  <section className={s.root}>
    <Container>
      <h2 className={s.title}>Trusted by many</h2>

      <div className={s.logos}>
        {wallets.map(({ logoSrc, name }) => (
          <div className={s.logoContainer}>
            <img src={logoSrc} className={s.logo} alt={name} />
          </div>
        ))}
      </div>
    </Container>
  </section>
);

export default Wallets;
