import cn from 'classnames';
import s from './Safety.module.css';

interface ISafetyProps {
  className?: string;
}

const OtherAuditors: React.FC<ISafetyProps> = () => (
  <div className={cn(s.otherAuditors, s.linkWrapper)}>
    <a className={s.otherAuditsLink}>
      + and more <br />
      to the growth of the DEFI ecosystem on Solana.
    </a>
  </div>
);

export default OtherAuditors;
