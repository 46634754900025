import cn from 'classnames';
import Container from '../Container/Container';
import Link from '../Link/Link';
import s from './VenusPrime.module.css';

const VenusPrime: React.FC = () => (
  <Container className={s.root}>
    <div className={s.card} key="bounty">
      <div className={cn(s.backgroundImg, s.venusPrimeLogo)} />
      <div className={s.logoMobile} />
      <div className={s.textWrapper}>
        <h2>
        Mission 
 <span className={s.accent}> Sol Swap Bot</span>
        </h2>
        <p>
        At SolSwapBot, we aim to make advanced trading tools on the Solana network accessible to everyone. Our user-friendly platform enhances trading strategies and fosters a knowledgeable community, transforming the trading experience to be efficient and rewarding for all users.
        </p>
        {/* <Link className={s.link} href="https://docs-v4.venus.io/whats-new/prime-yield">
          Learn more
        </Link> */}
      </div>
    </div>
  </Container>
);

export default VenusPrime;
