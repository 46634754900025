import cn from 'classnames';
import Container from '../Container/Container';
import s from './Protection.module.css';

interface IProtectionProps {
  className?: string;
}

const Protection: React.FC<IProtectionProps> = ({ className }) => (
  <Container className={cn(s.root, className)}>
    <ul className={s.list}>
      <li className={cn(s.item, s.bugBounty)} key="bounty">
        <div className={s.textWrapper}>
          <h2>Risk Management</h2>
          <p>
          Manage risk with stop-loss orders, take-profit orders, and position sizing controls.
          </p>
        </div>
        <div className={cn(s.backgroundImg, s.bugBountyImg)} />
      </li>
      <li className={cn(s.item, s.protectionPrioritized)} key="protection">
        <div className={s.textWrapper}>
          <h2>Execution</h2>
          <p>Automatically place and execute orders based on predefined trading strategies.</p>
        </div>
        <div className={cn(s.backgroundImg, s.protectionPrioritizedImg)} />
      </li>
      <li className={cn(s.item, s.protectionPrioritized)} key="tradeprotection">
        <div className={s.textWrapper}>
          <h2>Customizable Trading Strategies</h2>
          <p>Implement and tailor trading strategies using technical indicators, market conditions, and personal preferences.</p>
        </div>
        <div className={cn(s.backgroundImg, s.protectionPrioritized2Img)} />
      </li>
    </ul>
  </Container>
);

export default Protection;
